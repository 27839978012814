import { classes } from '@/next-utils/css-utils/scss-utils';
import type IStylable from '@/react/components/traits/IStylable';
import type { ExperienceID } from '@/services/isomorphic/PersonalizationService';
import type { IPage } from '@/services/models/Page';
import type { Nullable } from '@/type-utils';
import { areAllUniqueBy } from '@/utils/array-utils';
import { observer } from 'mobx-react-lite';
import {
  Children,
  isValidElement,
  type ComponentProps,
  type PropsWithChildren,
  type ReactElement
} from 'react';
import { Decision as DecisionFC } from '../../../Decision';
import { ExperienceProvider } from '../../ExperienceProvider';
import { DynamicExperienceError } from '../DynamicExperienceError';
import { useDecision } from '../useDecision';

import S from './styles.module.scss';

export interface IInternalDynamicExperienceProps<EID extends ExperienceID>
  extends PropsWithChildren<IStylable> {
  /**
   * The children of the `DynamicExperience`, should contain `Decisions` that will
   * be selectively rendered based on whether they have been chosen or not.
   */
  children: PropsWithChildren['children'];
  /**
   * The experience id that is going to be queried to get
   * the proper decision to show.
   */
  experienceID: EID;
  /** The page that the experience is being rendered on. */
  page?: Nullable<IPage>;
}

/**
 * Internal component that manages the shared state of the children decisions.
 * If a decision cannot be made, it will throw an error to the error boundary
 * in the wrapper component.
 */
export const InternalDynamicExperience = observer(
  <T extends ExperienceID>({
    experienceID,
    children,
    page,
    id,
    className,
    style
  }: IInternalDynamicExperienceProps<T>) => {
    const decision = useDecision(experienceID, { page });

    // check that `DynamicExperience` is being used correctly
    const childrenArray = Children.toArray(children);
    if (childrenArray.length === 0) {
      throw new DynamicExperienceError(
        `DynamicExperience '${experienceID}' must have at least one child Decision element.`
      );
    }

    if (!childrenArray.every(isDecisionElement)) {
      throw new DynamicExperienceError(
        `All children of DynamicExperience '${experienceID}' must be Decision elements.`
      );
    }

    const decisionElements = childrenArray as Array<DecisionElement>;

    if (!areAllUniqueBy(decisionElements, (element) => element.props.id)) {
      throw new DynamicExperienceError(
        `Decision elements in DynamicExperience '${experienceID}' must have unique IDs.`
      );
    }

    if (!!decision) {
      const { decisionID } = decision;
      const supportedDecisionIDs = decisionElements.map(
        (element) => element.props.id
      );

      if (!supportedDecisionIDs.includes(decisionID)) {
        throw new DynamicExperienceError(
          `Decision with ID "${decisionID}" is not supported by a DynamicExperience with ID "${experienceID}".`
        );
      }
    }

    return (
      <ExperienceProvider experienceID={experienceID} decision={decision}>
        {/**
         * It would be cool if we can support animating layout shifts, in the event that
         * children vary in size. Framer Motion might be the right tool, but it's not as
         * simple as `<motion.div layout>`.
         */}
        <div
          id={id}
          className={classes(S.dynamicExperience, className)}
          style={style}
        >
          {children}
        </div>
      </ExperienceProvider>
    );
  }
);

/**
 * A `Decision` React element. This is what `<Decision />` returns.
 */
type DecisionElement = ReactElement<
  ComponentProps<typeof DecisionFC>,
  typeof DecisionFC
>;

/**
 * Helper function to determine if a child is a `Decision` component.
 * @param child - The child to check.
 * @returns `true` if the child is a `Decision` component, `false` otherwise.
 */
function isDecisionElement(child: unknown): child is DecisionElement {
  return isValidElement(child) && child.type === DecisionFC;
}
